/* You can add global styles to this file, and also import other style files */
* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    width: 100%;
}

ul, li{
    list-style-type: none;
}